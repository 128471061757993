import React　from 'react'
import { useForm, Controller } from 'react-hook-form'
import Button from '@material-ui/core/Button'
import {Container, makeStyles} from "@material-ui/core";
import TextField from '@material-ui/core/TextField';

import Typography from '@material-ui/core/Typography';
import Checkbox from '@material-ui/core/Checkbox';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Paper from '@material-ui/core/Paper';
import Card from '@material-ui/core/Card';
import PhoneIcon from '@material-ui/icons/Phone';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';

import Layout from "../components/layout"
import Seo from "../components/seo"
import Image from "../components/image"

const types = [
  {
    id: 0,
    name: "製品全般についてのお問い合わせ"
  },
  {
    id: 1,
    name: "技術的なお問い合わせ"
  },
  {
    id: 2,
    name: "その他"
  },
];

// スタイルの記述をする
const useStyles = makeStyles((theme) => ({
  phrase: {
    color: "gray",
    textAlign: 'center',
    paddingTop: 150,
    marginLeft: 30,
    marginBottom: 50,
    marginRight: 30,
  },
  content: {
    marginTop: 30, 
    display: 'grid'
  },
  form: {
    marginTop: 50,
    marginBottom: 100,
  },
  error: {
    color: "#f44336"
  },
  checkGroup: {
    marginLeft: 20,
    marginRight: 2
  }
  
}));

const Error = ({arg, className}) => {
  return (arg!==undefined && <p className={className} key={arg.type}>{arg.message}</p>)
}

const Form = (props) => {
  const { register, handleSubmit, formState: { errors }, control,getValues } = useForm()

  const onSubmit = async data => {
    console.log(data);
    // Slack通知
    await fetch('https://hooks.slack.com/services/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', {
      method: 'post',
      headers: {
        'Accept': 'application/json, text/plain, */*',
        'Content-type': 'application/x-www-form-urlencoded'
      },
      body: JSON.stringify({
        text: 
          `稲作支援SaaS問い合わせ内容
          会社: ${data.company} 
          名前: ${data.name}
          メールアドレス: ${data.email}
          問い合わせ内容: ${data.message}`
        })
    })
    .then(response => console.log)
    .catch(error => console.error);

    // Teams通知（corsで通知できず）
    // const query_params = new URLSearchParams(params); 
    // const teamsURL='https://stansdev7.webhook.office.com/webhookb2/17636fb2-2b49-4dee-95f0-ec20dd1336b1@d699582b-e013-4ba7-9cd5-bfdc0af48aa8/IncomingWebhook/94b2dd8d510f418da64dd9f6f079e151/47c2f67b-9d40-4261-8de2-25ce24aab0ad'
    // fetch(teamsURL + query_params,{method: 'post', mode: 'cors',
    // headers: { 'Accept': 'application/json, text/plain, */*',
    // 'Content-type': 'application/json',
    // 'Content-type': 'application/x-www-form-urlencoded'}}
    // ).then(response => {
		// 	console.log(response)
		// 	alert("teams")
		// }).catch(error => {
		// 	console.log(error)
		// 	alert(error)
		// })

    //e.preventDefault()
    let myForm = document.getElementById('contact_form');
    let formData = new FormData(myForm)
    await fetch('/', {
      method: 'POST',
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: new URLSearchParams(formData).toString()
    }).then(() => console.log('Form successfully submitted')).catch((error) => alert(error))


    window.location.href='/thanks';
  };

  const classes = useStyles();

  const handleCheck = checkedId => {
    const { type_ids: ids } = getValues();
    console.log(ids)
    const newIds = ids?.includes(checkedId) ? ids?.filter(id => id !== checkedId) : [...(ids ?? []), checkedId];
    return newIds;
  };

  return (
    <Container>

      <form id= "contact_form" name="contact" 
        data-netlify="true"
        data-netlify-honeypot="bot-field" 
        method="POST" 
        className={classes.form} onSubmit={handleSubmit(onSubmit) }>
      
          <input type="hidden" name="form-name" value="contact" />
          <input type="hidden" name="bot-field" />
        

          <div className={classes.content}>
            <Paper style={errors.type_ids ? {color: "red"} : null}>
            <h3>お問い合わせ種別 *</h3>
            <Controller
              name="type_ids"
              render={({ field: { value, onChange } }) => (
                <FormGroup row className={classes.checkGroup}>
                {types.map((item, index) => (
                  <FormControlLabel
                    control={ <Checkbox onChange={() => onChange(handleCheck(item.id))} /> }
                    key={item.id}
                    label={item.name}
                    
                  />
                ))}
                </FormGroup>
              )}
              rules={{ required: "必須入力です" }}
              control={control}
            />
            </Paper>
            <Error className={classes.error} arg={errors.type_ids} />

          </div>
          <div className={classes.content}>
            <TextField id="outlined-company" label="会社名 *" variant="outlined" placeholder="ステンシステム株式会社" error={Boolean(errors.company,)} {...register("company", { required: "必須入力です", pattern: {value: /^\S+/i, message: "不正な入力です"},　maxLength: {value: 200, message: "最大文字数は200文字です"}}) } />
            <Error className={classes.error} arg={errors.company} />
          </div>
          <div className={classes.content}>
            <TextField id="outlined-department" label="部署 *" variant="outlined"  placeholder="システム部" error={Boolean(errors.department)} {...register("department", { required: "必須入力です", pattern: {value: /^\S+/i, message: "不正な入力です"},　maxLength: {value: 200, message: "最大文字数は200文字です"} })} />
            <Error className={classes.error} arg={errors.department} />
          </div>
          <div className={classes.content}>
            <TextField id="outlined-position" label="役職" variant="outlined"  placeholder="一般" error={Boolean(errors.position)} {...register("position", { pattern: {value: /^\S+/i, message: "不正な入力です"},　maxLength: {value: 200, message: "最大文字数は200文字です"} })}/>
            <Error className={classes.error} arg={errors.position} />
          </div>
          <div className={classes.content}>
            <TextField id="outlined-name" label="お名前 *" variant="outlined"  placeholder="徳島　太郎" error={Boolean(errors.name)} {...register("name", { required: "必須入力です", pattern: {value: /^\S+/i, message: "不正な入力です"},　maxLength: {value: 200, message: "最大文字数は200文字です"} })}/>
            <Error className={classes.error} arg={errors.name} />
          </div>
          <div className={classes.content}>
            <TextField id="outlined-address" label="住所" variant="outlined"  placeholder="△△県□□市〇〇町1−1　XXビル" error={Boolean(errors.address)} {...register("address", { pattern: {value: /^\S+/i, message: "不正な入力です"},　maxLength: {value: 200, message: "最大文字数は200文字です"} })} />
            <Error className={classes.error} arg={errors.address} />
          </div>
          <div className={classes.content}>
            <TextField id="outlined-tel" label="電話番号(ハイフンなし)" variant="outlined"  placeholder="0000000000"  error={Boolean(errors.tel)} {...register("tel", { pattern: {value: /^[0-9]+$/i, message: "不正な入力です"},　maxLength: {value: 11, message: "最大文字数は11文字です"} }) }/>
            <Error className={classes.error} arg={errors.tel} />
          </div>
          <div className={classes.content}>
            <TextField id="outlined-email" label="メールアドレス *" variant="outlined"  placeholder="〇〇〇〇＠example.com" error={Boolean(errors.email)} {...register("email", { required: "必須入力です", pattern: {value: /^[a-zA-Z0-9_.+-]+@([a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9]*\.)+[a-zA-Z]{2,}$/i, message: "不正な入力です"}, maxLength: {value: 100, message: "最大文字数は100文字です"}  })} />
            <Error className={classes.error} arg={errors.email} />
          </div>
          <div className={classes.content}>
            <TextField id="outlined-message" label="お問い合わせ内容 *" variant="outlined"  placeholder="" error={Boolean(errors.message)} {...register("message", { required: "必須入力です", maxLength: {value: 1000, message: "最大文字数は1000文字です"}}) }   multiline rows={4} rowsMax={8} />
            <Error className={classes.error} arg={errors.message} />
          </div>
					<div className={classes.content} >
						
						<Card style={{height: "200px", overflowY: "auto"}}>
      				<CardContent>
								<h4>プライバシーポリシー<br /></h4>
							<div style={{marginTop: 20}}>
								ご入力頂いた個人情報は、下記の目的で利用いたします。<br />
								その他の取扱いについては
                <a href="https://www.stansystem.co.jp/info/privacy_policy"　target="_blank" rel="noreferrer">
                  弊社プライバシーポリシー
                </a>に従います。
							</div>
							<div style={{margin: 25}}>
								<ul>
									<li>お問い合わせへの回答のため</li>
									<li>ご本人確認と個別のご連絡のため</li>
									<li>広告宣伝(電子メールによるものを含む)、アンケート、市場調査のため</li>
									<li>サービス品質改善に向けた利用分析及び統計データ作成のため</li>
								</ul>
							</div>
							<div>上記の内容でよろしければ送信ボタンを押してください。送信ボタンを押すと同意したものとさせていただきます。</div>
								<CardActions>
                  <a href="https://www.stansystem.co.jp/info/privacy_policy"　target="_blank" rel="noreferrer">
                    弊社プライバシーポリシー
                  </a>
								</CardActions>
							</CardContent>
						</Card>
					</div>
          <div className={classes.content}><Button style={{fontSize: "18px"}} variant="contained" color="primary" type="submit">送信</Button></div>
        
      </form>
      <hr/>
      <Typography variant="h4" style={{marginTop: 50}}>
        
      </Typography>
      <div id= "contact_tel" style = {{marginTop: 100, marginBottom: 150}}>
        <Typography variant="h4" style={{marginBottom: 50}}>
          電話でのお問合せ
        </Typography>
        <div>
        { props.breakpoints.sm ? 
            <a href="tel:088-602-7777" style = {{textDecoration: "none", }}>
              <Button style={{minWidth: "100%", fontSize: "22px"}} variant="contained" color="secondary" type="submit">
                <PhoneIcon style={{fontSize: "40px"}}/><div style={{paddingLeft: 20}}>タップで電話発信<p style={{fontSize: "12px"}}>(受付時間：月～金 9:00～18:00)</p></div>
              </Button>
            </a> 
            : 
            <div style={{textAlign: "center",fontSize: "24px"}}>
              <p>稲作支援SaaS　お問い合わせ窓口</p>
              <p style={{fontSize: "36px"}}><strong><PhoneIcon style={{fontSize: "40px"}}/> 088-602-7777</strong></p>
              <p>受付時間：月～金 9:00～18:00</p> 
            </div>
        } 
        </div>
      </div>
    </Container>
  );
}

const ContactPage = (props) => {
  const breakpoints = useBreakpoint();
  const classes = useStyles();
  return (
    <Layout location={props.location} breakpoints={breakpoints}>
    <Seo title="お問合せ" />
      <div  style={{position: "fixed", top: 0, minWidth: "100%", height: "40vh", zIndex: -1,backgroundColor: "gainsboro"}}><Image filename="overview_background.png" style={{height: "inherit"}}/></div>
      <div className={classes.phrase} style = {{color: "white",fontSize: breakpoints?.sm ? 28 : breakpoints.md ? 36 : 42}}>
        お問合せ
      </div>
      <div style={{backgroundColor: "white", paddingTop: 50}}>
        {breakpoints && <Form breakpoints = {breakpoints}/>}
      </div>
    </Layout>
  )
}

export default ContactPage